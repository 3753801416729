$black: #121212 !default
$white: #fff !default
$grey: #707070 !default
$dark-grey: #202020 !default
$dark-grey2: #232323 !default
$dark-grey3: #292929 !default
$grey-form: #181818 !default
$border: #363636 !default
$placeholder: #4B4B4B !default

$orange-active: #684100 !default
$orange: #F9B036 !default
$green: #23C381 !default
$red: #D33031 !default
$blue: #0084FF !default

$hover: $orange !default


$text: $white !default
$link: $white !default
$bg-base: $black !default

$font-size: 16 !default

$font-title: 'Plus Jakarta Sans', 'Roboto'
$font-base: 'Plus Jakarta Sans', 'Roboto'
$font-additional: 'Inter'


$grid-breakpoints: (xs: 0,b375: 375px,sm: 428px,b680: 680px,md: 768px,lg: 958px,xl: 1280px,b1400: 1440px,b1600: 1600px,xxl: 1920px) !default
