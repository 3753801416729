@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'


.notFound
	display: flex
	align-items: center
	justify-content: center
	height: 100%
	padding: rems(80) rems(24) rems(120)

	@include media-breakpoint-up(md)
		padding: rems(120) rems(24) rems(160)


	&__wrap
		text-align: center

	&__title
		letter-spacing: -20px
		@include fontSize(160,160)
		color: #191C1F
		font-weight: 800
		background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(195, 189, 189, 0.47) 17.19%, rgba(111, 111, 111, 0.81) 40.1%, #2d2d2d 73.96%)
		-webkit-background-clip: text
		-webkit-text-fill-color: transparent

		@include media-breakpoint-up(sm)
			@include fontSize(220,220)

		@include media-breakpoint-up(md)
			@include fontSize(300,300)

	&__img
		display: none
		@include media-breakpoint-up(md)
			display: block
			margin-top: rems(-165)

	&__text
		@include fontSize(14,25)
		color: $white
		font-weight: 500
		margin-top: rems(24)

		@include media-breakpoint-up(md)
			margin-top: rems(36)