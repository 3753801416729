@import '../../../../styles/mixins'
@import '../../../../styles/variables'
@import '../../../../styles/functions'

.top-balance
	border-radius: 8px
	margin-bottom: rems(36)
	max-width: 713px

	@include media-breakpoint-up(b680)
		padding: rems(24)

	@include media-breakpoint-up(md)
		padding: rems(30)
		background: $grey-form

	&__wrapper

		@include media-breakpoint-up(md)
			display: flex
			align-items: center
			justify-content: space-between

	&__left
		margin-right: rems(16)

	&__link
		color: $orange
		font-weight: 500
		@include fontSize(12,22)
		margin-bottom: rems(13)
		display: flex
		align-items: center

		&-icon
			margin-right: rems(8)

	&__title
		font-weight: 600
		@include fontSize(26,32)
		color: $white

		@include media-breakpoint-up(md)
			@include fontSize(32,44)

	&__right
		display: flex
		align-items: center
		margin-top: rems(24)
		@include media-breakpoint-up(md)
			margin-top: 0

	&__balance
		font-weight: 600
		@include fontSize(22,30)
		color: $white

		@include media-breakpoint-up(md)
			@include fontSize(24,33)

.withdrawal
	&__currency
		margin-bottom: rems(50)

	&__tooltip
		margin-bottom: rems(10)

		&-i
			margin-left: rems(4)

	&__title
		font-weight: 500
		@include fontSize(14,25)
		color: $white

	&__list
		margin-left: rems(-15)
		margin-top: rems(-15)
		display: flex
		overflow-x: auto

		@include media-breakpoint-up(md)
			align-items: center
			flex-wrap: wrap

	&__item
		padding-top: rems(15)
		padding-left: rems(15)

	&__btn
		width: 100%
		@include media-breakpoint-up(b680)
			width: auto

	&__inputs
		margin-bottom: rems(24)

.crypto-btn
	display: flex
	align-items: center
	width: rems(120)
	padding: rems(10) rems(15)
	border-radius: 8px
	background-color: $dark-grey2
	border: 2px solid $dark-grey2
	transition: 0.3s

	@include media-breakpoint-up(md)
		width: rems(145.5)

	&.active
		border: 2px solid $orange!important

	&__icon
		display: block
		height: 18px
		width: 18px
		margin-right: rems(10)

	&__text
		font-weight: 600
		@include fontSize(14,25)
		color: $white
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis

.crypto-info
	padding: rems(20)
	border: 2px solid $dark-grey2
	border-radius: 10px
	margin-bottom: rems(24)
	
	&--disabled
		opacity: 0.3

	&--confirm
		border: 2px solid $dark-grey2
		margin-top: rems(20)
		background-color: $dark-grey2

	&--border
		border: 3px dashed $dark-grey2

	&__list

	&__item
		display: flex
		align-items: center
		justify-content: space-between

		& + &
			margin-top: rems(8)

	&__text
		font-weight: 500
		@include fontSize(14,25)
		color: rgba($white, 0.4)

	&__value
		font-weight: 500
		@include fontSize(14,25)
		color: $white
		flex-shrink: 0
		margin-left: rems(12)
		white-space: nowrap
		text-align: right

		&--bg
			font-weight: 600
			color: $white
			padding: rems(2) rems(6)
			border-radius: 4px
			background-color: $grey
			opacity: 0.5

	&__rate
		font-weight: 400
		@include fontSize(12,22)
		color: rgba($white, 0.4)