@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.header
    padding: rems(12) rems(20) rems(18)
    background-color: $black

    //&--fixed
    //    padding: rems(12) rems(12) rems(12) rems(20)

    &__wrapper
        display: flex
        align-items: center
        justify-content: space-between

    &__left
        margin-right: rems(24)
        display: flex
        align-items: center

    &__logo
        svg
            width: rems(170)
            height: auto
            @include media-breakpoint-up(md)
                width: rems(212)

    &__right
        display: flex

    &__nav-list
        display: flex
        align-items: center

    &__nav-item
        & + &
            margin-left: rems(16)

    &__login
        display: inline-flex!important

    &__signUp
        height: 42px

        @include media-breakpoint-up(lg)
            min-width: 98px

    &__mini-btn
        padding: rems(11)
        border-radius: 4px
        background-color: $dark-grey
        display: none

        @include media-breakpoint-up(md)
            display: inline-flex

        &-text
            font-weight: 400
            @include fontSize(14,22)
            color: $white
            margin-left: rems(18)

    &__change-btn
        display: flex
        align-items: center

    &__change-btn-text
        font-weight: 500
        @include fontSize(14,25)
        color: $white
        margin-left: rems(8)

    &__support
        font-weight: 400!important

        svg
            margin-right: rems(18)

    &__link
        display: flex
        align-items: center
        font-weight: 500
        @include fontSize(14,24)
        color: $white
        margin-left: rems(16)

    &__logout
        height: auto
        padding: 0
        display: inline-flex
        align-items: center
        justify-content: center

.mini-select
    .MuiPaper-root
        background: $dark-grey!important
        margin-top: rems(20)

        .MuiList-root
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08)
            background: $dark-grey
            border-radius: 4px
            padding: 0!important

        .MuiButtonBase-root
            padding: rems(12) rems(16) rems(13)



//tradingPairs

.tradingPairs
    display: inline-block
    width: 100%

    @include media-breakpoint-up(md)
        position: relative
        width: auto
        margin-left: rems(20)

    @include media-breakpoint-up(b1400)
        margin-left: rems(90)

    &.active
        visibility: visible
        opacity: 1

        .tradingPairs__bg
            opacity: 1
            visibility: visible

        .tradingPairs__content
            transform: scale(1)
            opacity: 1
            visibility: visible

    &__bg
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 20
        visibility: hidden
        opacity: 0

    &__selected-wrapper
        display: flex
        align-items: center
        padding: rems(8) rems(10)
        background: $dark-grey
        border-radius: 4px
        justify-content: space-between
        height: rems(42)
        width: 100%

    &__selected
        display: flex
        align-items: center

    &__selected-info
        font-weight: 600
        @include fontSize(14,25)
        color: $white
        text-overflow: ellipsis
        overflow: hidden

    &__selected-icon
        margin-right: rems(8)
        display: inline-block
        height: 25px
        width: 25px

    &__arrow
        margin-left: rems(8)
        transform: rotate(180deg)

    &__content
        position: absolute
        left: rems(20)
        right: rems(20)
        top: rems(72)
        padding: rems(15)
        background: $dark-grey
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25)
        border-radius: 4px
        z-index: 100
        transform: scale(0.5)
        opacity: 0
        transition: 0.2s
        visibility: hidden
        width: calc(100% - rems(40))

        @include media-breakpoint-up(md)
            left: 0
            width: rems(340)
            right: unset
            top: calc(100% + 10px)


    &__title
        font-weight: 600
        @include fontSize(16,29)
        color: $white
        margin-bottom: rems(10)

    &__input
        background: #282828
        border-radius: 4px
        font-weight: 400
        @include fontSize(12,22)
        color: rgba($white,0.5)
        padding: rems(9) rems(10)
        width: 100%

        &:focus
            outline: none

    &__btns
        margin-top: rems(10)

    &__btn
        width: 100%
        padding: rems(10)
        background: #282828
        border: 1px solid transparent
        border-radius: 4px
        transition: 0.3s
        display: inline-flex
        align-items: center
        text-align: left
        font-weight: 600
        @include fontSize(12,22)
        color: rgba($white,0.5)

        &.active
            border: 1px solid #FBFBFB

        &-icon
            margin-right: rems(8)
            display: inline-block
            height: rems(20)
            width: rems(20)

    &__list
        margin-top: rems(20)
        max-height: rems(200)
        overflow-x: auto

        &::-webkit-scrollbar
          width: rems(7)

        &::-webkit-scrollbar-thumb
            background-color: #A9A9A9
            height: rems(91)
            border-radius: 20px

        &::-webkit-scrollbar-track
            background-color: transparent

    &__item

    &__item-btn
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        padding: rems(7.5) 0

    &__item-left
        display: flex
        align-items: center

    &__name
        font-weight: 600
        @include fontSize(14,25)
        color: $white

    &__item-right
        margin-right: rems(8)

    &__item-icon
        height: rems(25)
        width: rems(25)
        margin-right: rems(8)

    &__item-percent
        font-weight: 600
        @include fontSize(14,25)
        color: rgba($white,0.5)

.mobile-header
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

    &__burger
        flex-shrink: 0
        margin-right: rems(10)
        height: rems(42)
        width: rems(42)
        display: inline-flex
        align-items: center
        justify-content: center
        background-color: $dark-grey
        border-radius: 4px


    &__btn
        width: 100%
        margin-left: rems(10)

    &__btn-history
        flex-shrink: 0
        padding: rems(11)
        background: $dark-grey
        border-radius: 4px
        margin-left: rems(10)
        height: rems(42)
        width: rems(42)


//mode

.mode-select

    &.MuiInput-root
        min-width: 100%

        &:after,&:before
            display: none

        @include media-breakpoint-up(md)
            min-width: 162px

    .MuiSelect-select
        padding: rems(6) rems(40) rems(6) rems(16) !important
        background: $dark-grey!important
        border-radius: 4px

        @include media-breakpoint-up(md)
            padding: rems(3) rems(40) rems(3) rems(16) !important

        &:focus
            outline: none

    .MuiSelect-icon
        right: rems(16)

.mode-select-menu
    .MuiPaper-root
        background-color: transparent

    .MuiPaper-root
        margin-top: rems(10)

    .MuiList-root
        padding: rems(10)
        background-color: #212121
        box-shadow: 7px 15px 20px rgba(0, 0, 0, 0.35)
        border-radius: 10px

    .MuiMenuItem-root
        background: #313131!important
        border-radius: 4px
        border: 1px solid transparent
        padding: rems(3) rems(16)
        margin-bottom: rems(10)
        min-width: 205px!important

        @include media-breakpoint-up(md)
            min-width: 162px!important

        &:last-child
            margin-bottom: 0

        &.Mui-selected
            background: #313131!important
            border: 1px solid #F9B036

    .MuiButtonBase-root
        & + &
            margin-top: rems(10) !important

.mode-item
    display: flex
    align-items: center

    &__content

    &__name
        font-weight: 500
        @include fontSize(10,17)
        color: $grey

    &__value
        font-weight: 600
        @include fontSize(11,19)
        color: $white

    &__icon
        display: inline-block
        margin-right: rems(8)