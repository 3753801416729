@import '../../../../../styles/mixins'
@import '../../../../../styles/variables'
@import '../../../../../styles/functions'

.confirmWithdrawal-popup
	.popup__content
		width: 100%
		height: 100%

		@include media-breakpoint-up(b680)
			height: fit-content
			max-width: 433px
			width: 100%

.confirmWithdrawal
	padding: rems(65) rems(16) rems(32)
	background: $grey-form
	width: 100%
	height: 100%
	display: flex
	flex-direction: column

	@include media-breakpoint-up(b680)
		border-radius: 12px
		border: 1px solid $grey
		height: auto
		padding: rems(85) rems(32) rems(50)

	&__title
		font-weight: 600
		@include fontSize(20,32)
		color: $white
		margin-bottom: rems(5)

	&__text
		color: $white
		font-weight: 400
		@include fontSize(14,25)

	&__inp-wrap
		margin-top: rems(20)
		flex: 1

	&__wrap-btns
		display: flex
		gap: rems(15)
		margin-top: rems(20)

	&__btn
		flex: 0 1 50%
		
	&__2fa-btn
		margin-top: rems(20)

.confirm-info
	padding: rems(20)
	border: 2px solid $dark-grey2
	border-radius: 10px

	&--confirm
		border: 2px solid $dark-grey2
		margin-top: rems(20)
		background-color: $dark-grey2

	&--border
		border: 3px dashed $dark-grey2

	&__list

	&__item
		display: flex
		align-items: center
		justify-content: space-between
		flex-wrap: wrap

		& + &
			margin-top: rems(8)

	&__text
		font-weight: 500
		@include fontSize(14,25)
		color: rgba($white, 0.4)

	&__value
		font-weight: 500
		@include fontSize(14,25)
		color: $white
		flex-shrink: 0
		margin-left: rems(12)
		white-space: nowrap
		text-align: right

		&--bg
			font-weight: 600
			color: $white
			padding: rems(2) rems(6)
			border-radius: 4px
			background-color: $grey
			opacity: 0.5

	&__rate
		font-weight: 400
		@include fontSize(12,22)
		color: rgba($white, 0.4)