@import '../../../../styles/mixins'
@import '../../../../styles/variables'
@import '../../../../styles/functions'

.balance
	&__available
		border-radius: 8px
		background: $dark-grey2
		padding: rems(20)
		margin-bottom: rems(24)

	&__available-text
		color: $white
		@include fontSize(20,36)
		font-weight: 500

		span
			font-weight: 600
			margin: 0 rems(10)

.balance-card
	border-radius: 8px
	background: $dark-grey2
	padding: rems(20)
	width: 100%

	&__top
		display: flex
		align-items: center
		width: 100%

	&__text
		color: $white
		@include fontSize(14,25)
		font-weight: 500

	&__val
		font-weight: 600
		color: $white
		@include fontSize(25,45)

	&__btn
		margin-top: rems(24)

	&__help
		margin-left: rems(10)