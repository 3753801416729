@import '../../../../styles/mixins'
@import '../../../../styles/variables'
@import '../../../../styles/functions'

.status
	padding: rems(4) rems(10)
	border-radius: 4px
	display: flex
	width: fit-content
	align-items: center
	margin-left: auto

	&.verified
		background-color: $green

	&.unverified
		background-color: #D33031

	&.pending
		background-color: #FFB437

	&--full
		width: 100%
		justify-content: center

	&__icon
		margin-right: rems(10)
		height: 20px
		width: 20px


	&__text
		color: $white
		font-weight: 500
		@include fontSize(14,25)