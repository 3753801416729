@import '../../../styles/mixins'
@import '../../../styles/variables'
@import '../../../styles/functions'

.dashboard
	&__text
		margin-bottom: rems(24)
		color: $white
		font-weight: 600
		@include fontSize(20,32)

		span
			color: rgba($white,0.5)

.dashboard-visitors
	padding: rems(20)
	border-radius: 8px
	background: $dark-grey2
	margin-bottom: rems(35)

	&__wrapper
		@include media-breakpoint-up(md)
			display: flex

	&__graphics-wrap
		@include media-breakpoint-up(md)
			margin-right: rems(24)
			flex: 1 1 100%

	&__line-wrap
		width: 100%
		height: 180px
		
		@include media-breakpoint-up(md)
			height: 240px
		
		& + &
			margin-top: rems(32)

			@include media-breakpoint-up(md)
				margin-top: rems(72)
			
		canvas
			height: auto !important
			width: 100%!important

	&__line

	&__info-wrap
		margin-top: rems(32)
		@include media-breakpoint-up(md)
			margin-top: 0
			flex: 0 0 rems(250)

.dashboard-card
	padding: rems(25) rems(20)
	border-radius: 8px
	border: 0.5px solid #9C9C9C
	background: rgba(46, 46, 46, 0.50)
	display: flex
	flex-direction: column
	min-height: rems(266)

	& + &
		margin-top: rems(15)

	&__top
		margin-bottom: rems(24)

	&__title
		color: $white
		@include fontSize(14,25)
		font-weight: 500

	&__text
		color: rgba($white, 0.5)
		font-weight: 500
		@include fontSize(12,22)

	&__bottom
		margin-top: auto

	&__value
		color: $white
		@include fontSize(40,40)
		font-weight: 600
		margin-bottom: rems(5)

.dashboard-sum-info
	margin-top: rems(15)

.dashboard-info
	border-radius: 8px
	background-color: $dark-grey2

	&__wrap
		@include media-breakpoint-up(xl)
			display: flex

	&__graphic
		padding: rems(20) rems(20)
		flex: 0 0 33.333%

		& + &
			position: relative

			@include media-breakpoint-up(xl)
				&:before
					position: absolute
					content: ''
					width: 1px
					height: 100%
					background-color: #FDFDFD
					opacity: 0.5
					left: 0
					top: 0
					bottom: 0

	&__line
		height: 180px

		@include media-breakpoint-up(md)
			height: 240px
		
		canvas
			height: 100%!important
			width: 100%!important

	&__text
		color: $white
		@include fontSize(14,26)
		font-weight: 500

	&__value
		font-weight: 600
		@include fontSize(25,45)
		color: $white


.dashboard-visitors
	&__line

.dashboard-full-info
	margin-top: rems(35)

	&__help
		height: 100%
		margin-left: rems(5)

		svg
			vertical-align: middle

		path
			stroke: $white