@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.tabs
	display: flex
	overflow-x: auto
	.MuiTab-root
		padding: rems(25) rems(39)
		border-radius: 8px 8px 0px 0px
		color: $white!important
		font-weight: 600
		@include fontSize(12,22)
		white-space: nowrap
		text-transform: none
		font-family: $font-base

	.MuiTabs-indicator
		display: none

	.Mui-selected
		background-color: $grey-form

.tabs-t-history
	display: block
	overflow-x: unset

	@include media-breakpoint-up(md)
		display: flex

.tab-content
	overflow-x: auto

	&--scroll
		padding: rems(16)
		background-color: $grey-form

		@include media-breakpoint-up(md)
			border-radius: 0px 8px 8px 8px
			padding: rems(30)

	&.MuiTabPanel-root
		padding: rems(16)
		border-radius: 0px 8px 8px 8px
		background-color: $grey-form

		@include media-breakpoint-up(md)
			padding: rems(30)

	&.tab-content--mini
		&.MuiTabPanel-root
			padding: rems(15)