@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.btn
	display: inline-flex
	justify-content: center
	align-items: center
	padding: rems(8)
	font:
		family: $font-title
		size: rems(14)
	line-height: rems(25)
	font-weight: 600
	color: $black
	text-align: center
	white-space: nowrap
	text-decoration: none
	border-radius: 0
	cursor: pointer
	background-color: $white
	transition: .3s ease-in-out
	position: relative
	overflow: hidden


	&--mini,
	&--mini-w
		padding: rems(8.5) rems(16)
		border-radius: 4px
		font-weight: 600
		@include fontSize(14,25)
		height: 42px

		@include media-breakpoint-up(lg)
			@include fontSize(16,22)

	&--mini-w
		padding: rems(8.5) rems(24)

	&--middle
		padding: rems(13.5) rems(32)
		border-radius: 4px
		font-weight: 600
		@include fontSize(16,29)


	&--black
		background-color: $dark-grey
		color: $white

	&--orange
		background-color: $orange
		color: $black

		&:disabled
			background-color: $dark-grey2!important
			color: $placeholder!important

	&--border
		border: 1.5px solid $orange
		border-radius: 4px
		background-color: $dark-grey2
		color: $white

		&.active
			background-color: $orange
			color: $black

		&.disabled
			background-color: $dark-grey2!important
			color: $white!important

	&--grey
		background: $dark-grey2
		color: $white
		border-radius: 4px