@import '../../../styles/mixins'
@import '../../../styles/variables'
@import '../../../styles/functions'


.sidebar
	max-width: 249px
	box-shadow: 5px 0 20px rgba(0, 0, 0, 0.04)
	background-color: $black
	overflow-y: auto
	flex-shrink: 0
	position: fixed
	top: 0
	left: 0
	bottom: 0
	height: 100%
	width: 100%
	z-index: 100
	transform: translateX(-100%)
	padding: rems(80) 0 rems(30)
	transition: 0.3s
	
	@include media-breakpoint-up(lg)
		width: 249px
		padding: rems(20) 0
		height: calc(100% - 70px)
		position: relative
		transform: translateX(0)
		
	&.active
		transform: translateX(0)

	&__wrap
		height: 100%
		display: flex
		flex-direction: column
		
	&__bg
		position: fixed
		top: 0
		left: 0
		right: 0
		bottom: 0
		height: 100%
		width: 100%
		background: rgba(38, 36, 22, 0.14)
		backdrop-filter: blur(4px)
		z-index: 1
		visibility: hidden
		opacity: 0
		transition: 0.3s ease
		
		@include media-breakpoint-up(lg)
			display: none
		
		&.active
			visibility: visible
			opacity: 1


	&__btn
		display: block
		width: fit-content
		margin-left: auto
		margin-bottom: rems(16)

	&__nav

	&__header
		margin-bottom: rems(25)
		padding: 0 rems(22)

	&__name
		font-weight: 600
		@include fontSize(20,36)
		color: $white
		overflow: hidden
		text-overflow: ellipsis

	&__email
		font-weight: 600
		@include fontSize(14,25)
		color: rgba($white,0.5)
		overflow: hidden
		text-overflow: ellipsis

	&__close
		position: absolute
		top: rems(30)
		right:  rems(22)

	&__bottom
		padding: 0 rems(30)
		margin-top: auto
		
	&__support
		font-weight: 400!important
		width: 100%
		padding: rems(12)
		justify-content: flex-start!important
		text-align: left

		svg
			margin-right: rems(15)
		
	&__logout
		padding: rems(15) 0 0
		font-weight: 600
		@include fontSize(14,25)
		color: $grey
		width: 100%
		text-align: left
		
.nav
	&__list

	&__item

	&__link
		width: 100%
		padding: rems(20) rems(30)
		display: flex
		align-items: center
		font-weight: 400
		@include fontSize(15,22)
		color: $grey

		svg
			margin-right: rems(16)

		&.active
			color: white
			background-color: #202020

			path
				stroke: $white
