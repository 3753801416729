@import 'variables'
@import 'mixins'
@import 'functions'

@import "components/btns"
@import "components/tabs"
@import "components/calendar"
@import "components/radio-btn"
@import "components/accordion"
@import "components/pagination"

*
    box-sizing: border-box

html, body
    height: 100%
    width: 100%
    font:
        family: $font-base
        weight: 400
        size: #{$font-size}px
    color: $white
    line-height: 1

body
    position: relative
    background-color: $bg-base

h1, h2, h3, h4, h5, h6
    margin: 0
    font:
        family: $font-title
        weight: 400

p
    line-height: 1.35
    margin: 0

ul
    margin: 0
    padding: 0
    list-style-type: none

img
    max-width: 100%
    height: auto
    object-fit: cover

a
    color: $black
    transition: all 0.3s ease
    text-decoration: none

input
    background-color: transparent
    border: none

button
    border: none
    background-color: transparent
    padding: 0
    cursor: pointer

// replace
.bo-checkbox
    height: 0
    width: 0
    position: absolute
    opacity: 0

    &+label
        display: inline-flex
        align-items: flex-start
        user-select: none

    &+label::before
        content: ''
        display: inline-block
        width: 20px
        height: 20px
        border-radius: 3px
        flex-shrink: 0
        background-color: transparent
        border: 1px solid #2F2F2F

    &:checked+label::before,
    &.checked+label::before
        background-image: url("../assets/images/icons/checkOn.svg")
        background-repeat: no-repeat
        background-position: center center
        background-size: 12px 12px

    &-label
        font-weight: 400
        color: $white
        @include fontSize(14,25)
        cursor: pointer

        a
            color: $orange

        &:before
            margin-right: rems(18)

.tipog
    @include fontSize(20,30)
    color: $orange

.select
    background-color: $red
    min-width: 200px

    .MuiSelect-select
        padding: 0

.bo-skeleton
    &.MuiSkeleton-root
        background-color: $placeholder

.main-info
    border-radius: 8px

    @include media-breakpoint-up(md)
        background: $grey-form
        padding: rems(30)

    & + &
        margin-top: rems(16)
        
        @include media-breakpoint-up(md)
            margin-top: rems(30)
        
    &__title
        font-weight: 600
        color: $white
        margin-bottom: rems(24)
        @include fontSize(26,32)

        @include media-breakpoint-up(md)
            @include fontSize(32,44)

.tooltip
    display: flex
    align-items: center
    margin-bottom: rems(4)

    &__popper
        &.MuiTooltip-tooltip
            max-width: rems(164)
            padding: rems(10)
            background: #0B0B0B
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)
            border-radius: 10px
            color: $white
            font-weight: 500
            @include fontSize(10,16)

            @include media-breakpoint-up(xxl)
                @include fontSize(12,16)

        &--middle
            &.MuiTooltip-tooltip
                max-width: rems(254)

.table
    margin-top: 0
    overflow-x: auto


    &--mini
        .table__tr
            padding: rems(15)

            &--head
                padding-bottom: 0
                padding-top: 0

        .table__td
            padding: 0 rems(5)

    &__tr
        display: flex
        align-items: center
        padding: rems(15) rems(9)
        border-radius: 4px
        background-color: $dark-grey

        &--head
            background-color: transparent

            .table__text
                @include fontSize(12,22)

        & + &
            margin-top: rems(15)
            
        &--block
            display: block

    &__td
        padding: 0 rems(12)
        
        &--column
            display: flex
            flex-direction: column


    &__text
        font-weight: 500
        @include fontSize(14,25)
        color: $white
        width: fit-content
        display: flex

        span
            font-weight: 500
            color: rgba($white,0.5)
            @include fontSize(14,25)
            margin-left: rems(10)


        &--bg
            padding: rems(4) rems(8)
            background: #3C3B3B
            border-radius: 4px
            width: fit-content

        &--blue
            color: #2EA7EB
            word-break: break-all

        &--end
            margin-left: auto

    &__wrap
        display: flex
        align-items: center

    &__copy
        flex-shrink: 0
        margin-left: rems(10)