@import '../../../styles/mixins'
@import '../../../styles/variables'
@import '../../../styles/functions'

.affiliateLinks
	&__text
		font-weight: 500
		@include fontSize(16,28)
		color: $white
		margin-bottom: rems(10)

	&__warning
		border-radius: 4px
		border: 1px dashed $blue
		background: rgba(17, 17, 17, 0.50)
		padding: rems(13.5) rems(16)
		border-spacing: 5px
		margin-bottom: rems(30)

	&__warning-text
		color: $blue
		@include fontSize(16,28)
		font-weight: 400

	&__smartL
		display: flex
		padding: rems(15) rems(16)
		border-radius: 4px
		border: 0.5px solid #9C9C9C
		background: $dark-grey2

	&__btn
		border-radius: 4px
		background: #6A6A6A
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25)
		padding: rems(7)
		margin-right: rems(15)

	&__info

	&__smartL-link
		color: $white
		text-decoration: underline
		leading-trim: both
		text-edge: cap
		font-weight: 400
		@include fontSize(18,32)
		margin-bottom: rems(10)
		word-break: break-all

	&__smartL-text
		@include fontSize(14,25)
		color: rgba($white,0.5)
		font-weight: 500
		
	&__create-btn
		margin-top: rems(24)
	
.campaigns
	&__header
		margin-bottom: rems(32)
		
		@include media-breakpoint-up(md)
			margin-bottom: rems(54)
			
	&__status
		padding: rems(2) rems(10)
		border-radius: 4px
		//background-color: $blue
		color: $white
		@include fontSize(12,22)
		font-weight: 600
		width: fit-content
		white-space: nowrap
		border: 1px solid $blue
		width: 100%
		
		&--green
			//background-color: #239766
			border: 1px solid #239766
			background-color: transparent

		&--red
			background-color: transparent
			border: 1px solid #972323
			//background: #972323
			color: #fff
		
	&__btns
		display: flex
		align-items: center
		margin: rems(16) rems(-4) 0
		
	&__btn
		margin: 0 rems(4)
		
	&__link-btn
		padding: rems(2) rems(10)
		border-radius: 4px
		//background-color: $grey
		border: 1px solid $grey
		color: $white
		@include fontSize(12,22)
		font-weight: 600
		//width: fit-content
		white-space: nowrap
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		
		svg
			margin-right: rems(4)
		
.warning
	padding: rems(14) rems(16)
	border: 1px solid $blue
	background: rgba(17, 17, 17, 0.50)
	border-radius: 4px
	display: flex
	align-items: center
	
	&__icon
		flex-shrink: 0
	
	&__text
		@include fontSize(16,28)
		color: $blue
		font-weight: 400
		margin-left: rems(15)