@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.offers
	&__info
		padding: rems(108) rems(20) rems(40)
		display: none
		
		@include media-breakpoint-up(b1400)
			display: block

	&__info-title
		color: #909090
		font-weight: 600
		@include fontSize(16,28)

		@include media-breakpoint-up(b1400)
			text-align: right
			padding-bottom: rems(20)
		
	&__info-wrap
		@include media-breakpoint-up(b1400)
			&:first-child
				min-height: rems(124)
	
			&:nth-child(2)
				min-height: rems(78)
	
			&:nth-child(3)
				min-height: rems(49)
	
			&:nth-child(4)
				min-height: rems(78)
	
			&:nth-child(5)
				min-height: rems(49)
	
			&:nth-child(6)
				min-height: rems(49)
				
	&__bottom
		margin-top: rems(40)
		
		@include media-breakpoint-up(md)
			margin-top: rems(70)
			
		&-text
			color: $white
			font-weight: 600
			@include fontSize(16,28)
			padding-bottom: rems(20)
			
		&-title
			color: #909090
			font-weight: 600
			@include fontSize(16,28)
			
			@include media-breakpoint-up(md)
				padding-bottom: rems(20)
			
			@include media-breakpoint-up(b1400)
				text-align: right

	&__progress
		width: 100%
		border-radius: 8px
		background-color: $dark-grey2
		height: 8px
		overflow: hidden
		margin-bottom:  rems(15)
		
		&-indicator
			background-color: $blue
			height: 100%
			border-radius: 8px
			width: 0
			transition: 0.3s
			
				
.offer-card
	padding: rems(20)
	border-radius: 8px
	background-color: $dark-grey2
	height: calc(100% - 25px)
	
	&--active
		border: 2px solid $blue
		box-shadow: 0px 0px 13.9px 0px rgba(0, 84, 163, 0.50) inset

	&__title
		color: $blue
		font-weight: 800
		@include fontSize(25,45)
		margin-bottom: rems(20)

	&__info-title
		color: $white
		font-weight: 600
		@include fontSize(16,28)
		
		
	&__info-item
		padding-bottom: rems(20)
		
		
	& .offer-card__info-item
		@include media-breakpoint-up(b1400)
			&:first-child
				min-height: rems(124)
	
			&:nth-child(2)
				min-height: rems(78)
				
			&:nth-child(3)
				min-height: rems(49)
	
			&:nth-child(4)
				min-height: rems(78)
	
			&:nth-child(5)
				min-height: rems(49)
	
			&:nth-child(6)
				min-height: rems(49)

	&__text
		color: $placeholder
		@include fontSize(14,25)
		font-weight: 500
		
	.offers__info-title
		@include media-breakpoint-up(b1400)
			display: none