@import "functions"

@function breakpoint-min($name, $breakpoints: $grid-breakpoints)
	$min: map-get($breakpoints, $name)
	@return if($min != 0, $min, null)


@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints)
	$min: breakpoint-min($name, $breakpoints)
	@if $min
		@media (min-width: $min)
			@content
	@else
		@content

@mixin fontSize($size, $lHeight)
	font-size: rems($size)
	line-height: rems($lHeight)