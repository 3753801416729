@import '../../../../styles/mixins'
@import '../../../../styles/variables'
@import '../../../../styles/functions'

.popup
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	width: 100%
	z-index: 1000
	visibility: hidden
	opacity: 0
	transition: all 0.3s ease
	display: flex
	justify-content: center
	padding: multipleEight(3)
	overflow-y: auto
	backdrop-filter: blur(4px)
	background: rgba(38, 36, 22, 0.14)

	&.active
		visibility: visible
		opacity: 1

	&__content
		position: relative
		z-index: 10
		height: fit-content
		margin: auto

	&__close
		position: absolute
		top: 24px
		right: 32px
