@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.base-wrap
	position: relative
	z-index: 1
	display: flex
	flex-direction: column
	height: 100%
	width: 100%
	overflow-x: clip
	&__header
		flex: 0 0 auto
	&__content
		flex: 1 0 auto
	&__footer
		flex: 0 0 auto


.main-content
	height: 100%
	display: flex

	&__wrapper
		padding: rems(12) rems(30) rems(35) rems(27)
		//margin-left: 249px
		width: 100%
		
		@include media-breakpoint-up(lg)
			width: calc(100% - 249px)
