@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.check
	padding: rems(80) rems(32) rems(90)
	border-radius: 12px
	border: 3px solid #4E4E4E
	background-color: $grey-form
	max-width: 387px
	width: 100%
	text-align: center

	&__icon
		margin-bottom: rems(8)

	&__title
		font-weight: 600
		@include fontSize(28,32)
		color: $white
		margin-bottom: rems(8)

		@include media-breakpoint-up(md)
			@include fontSize(32,51)

	&__text
		color: $white
		font-weight: 400
		@include fontSize(14,25)
