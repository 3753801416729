.bo-radio

	.MuiButtonBase-root
		color: rgba($white,0.4)
		padding: rems(16)

		&.Mui-checked
			color: $orange

	.MuiSvgIcon-root
		height: rems(20)
		width: rems(20)

	.MuiTypography-root
		font-weight: 500
		@include fontSize(16,29)
		color: $white

	&.MuiFormControlLabel-root
		margin-left: 0
		margin-right: 0