@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.accordion

	&__icon
		color: rgba($grey, 0.8)

	&__text
		font-weight: 400
		@include fontSize(14,24)
		color: $white

	.MuiAccordionSummary-expandIconWrapper
		margin-right: rems(10)



	.MuiAccordionSummary-content
		margin: rems(10) 0!important

	&.MuiAccordion-root
		background-color: transparent
		color: rgba($grey, 0.8)
		font-weight: 600
		@include fontSize(14,24)
		box-shadow: none

		&.Mui-expanded
			margin-bottom: rems(5)
		&:before
			display: none

	.MuiAccordionSummary-root
		flex-direction: row-reverse
		border-bottom: 1px solid rgba(#8E8EA9, 0.3)
		min-height: auto!important
		padding: 0 rems(10)

	.MuiAccordionDetails-root
		padding: rems(10) 0 rems(10) rems(10)