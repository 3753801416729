@import './src/styles/mixins'
@import './src/styles/variables'
@import './src/styles/functions'

.main-start
	background-color: #1E1E1E
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	padding: rems(80) rems(16) rems(120)
	position: relative
	overflow: hidden

	@include media-breakpoint-up(md)
		padding: rems(100) 0 rems(240)

	&__content
		max-width: rems(442)
		width: 100%
		position: relative

		@include media-breakpoint-up(lg)
			max-width: rems(542)

	&__title
		font-weight: 800
		@include fontSize(58,80)
		text-align: center
		margin-bottom: rems(30)

		@include media-breakpoint-up(lg)
			@include fontSize(80,96)

	&__list
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center

	&__item
		display: flex
		align-items: center
		& + &
			margin-top: rems(5)

		svg
			margin-right: rems(10)

	&__item-text
		font-weight: 500
		color: $white
		@include fontSize(16,24)

		@include media-breakpoint-up(lg)
			@include fontSize(18,32)

	&__btn
		margin-top: rems(60)
		width: 100%
		border-radius: 4px
		color: $white!important
		background: linear-gradient(91deg, #F9B036 6.8%, #E35224 104.88%)
		font-weight: 800
		@include fontSize(16,28)
		padding: rems(13.5) rems(24)
		display: inline-flex
		align-items: center
		justify-content: center
		text-align: center

	&__dec1
		position: absolute
		top: rems(80)
		left: 0
		animation: wawe2 5s ease-in-out infinite alternate
		display: none

		@include media-breakpoint-up(md)
			display: block

		@include media-breakpoint-up(xl)
			top: rems(102)
			left: 12%


	&__dec2
		position: absolute
		animation: wawe 5s ease-in-out infinite alternate
		right: 0
		top: rems(200)
		display: none

		@include media-breakpoint-up(md)
			display: block

		@include media-breakpoint-up(xl)
			top: rems(250)
			right: 10%


	&__dec3
		position: absolute
		filter: blur(7px)
		display: none

		@include media-breakpoint-up(xl)
			display: block
			left: -15%
			bottom: 34px


	&__dec4
		position: absolute
		filter: blur(6px)
		bottom: -35%
		right: -15%
		display: none

		@include media-breakpoint-up(md)
			display: block

		@include media-breakpoint-up(xl)
			bottom: -27%
			right: -10%


@keyframes wawe
	0%
		transform: translateY(0)

	50%
		transform: translateY(-20px)

	100%
		transform: translateY(20px)

@keyframes wawe2
	0%
		transform: translateY(0)

	50%
		transform: translateY(20px)

	100%
		transform: translateY(-20px)